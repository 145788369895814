<template>
  <div>
    <b-modal
      id="add-expense"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Add Expense')"
      size="l"
      hide-footer
      @close="onCancel"
      @shown="handleModalShown"
    >
      <b-form class="vacation">
        <b-form-group
          id="input-group-5"
          :label="$t('Account')"
          label-for="input-2"
        >
          <vue-select
            id="input-5"
            v-model="reason"
            required
            :options="vacationTime"
            :reduce="(e) => e.value"
            label="label"
            :placeholder="$t('Select Account')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.reason.required"
            style="color: red"
          >{{ $t("Reason") }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="$t('Expense type')"
          label-for="input-2"
        >
          <vue-select
            id="input-5"
            v-model="expense"
            required
            :options="expenses"
            :placeholder="$t('Select type')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.reason.required"
            style="color: red"
          >{{ $t("Reason") }}</span>
        </b-form-group>
        <b-form-group
          v-if="showInput"
          id="input-group-8"
          :label="$t('OtherReason')"
          label-for="input-25"
        >
          <b-form-input
            id="input-8"
            v-model="form.vacationType"
            style="height: 80px"
          />
        </b-form-group>
      </b-form>
      <b-form-group
        :label="$t('Description')"
      >

        <b-form-textarea
          id="input-15"
          v-model="form.notes"
        />

      </b-form-group>
      <b-form>
        <b-form-group
          id="input-group-4"
          :label="$t('Price')"
          label-for="input-9"
        >
          <b-form-input
            v-model="time1"
            :placeholder="$t('Type amount')"
          />
          <span
            v-show="formSubmitted && !$v.time1.required"
            style="color: red"
          >{{ $t('PlsSelectD') }}</span>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-sizes"
          :name="`checkbox`"
          :value="true"
          :unchecked-value="false"
        >
          {{ $t('Include TVSH') }}
        </b-form-checkbox>
        <b-form-group
          id="input-group-4"
          :label="$t('Execution Date')"
          label-for="input-9"
        >
          <date-picker
            v-model="time1"
            type="date"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'DD-MM-YYYY'"
          />
          <span
            v-show="formSubmitted && !$v.time1.required"
            style="color: red"
          >{{ $t('PlsSelectD') }}</span>
        </b-form-group>
      </b-form>

      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="background: linear-gradient(52deg, rgb(38, 46, 108) 0%, rgb(155, 42, 92) 100%, rgb(255, 39, 79) 100%) 0% 0% no-repeat padding-box padding-box transparent;"
          class="buttonSubmit"
          @click="onSubmitRequestVacation"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;
              background: white;
              color: black;
              border-color: white;
              font-weight: 400;"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone';

import { required } from 'vuelidate/lib/validators';
import { client } from '../../../domainConfig'


export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedId', 'userObj', 'date'],
  data() {
    return {
      resettingForm: Boolean,
      replace: false,
      manager: '',
      user: {},
      client: client.clientName,
      substituteEmployee: null,
      selectedItemId: null,
      sixmonth: false,
      reason: '',
      showInput: false,
      time1: null,
      formSubmitted: false,
      time2: null,
      vacation1: [
        { value: 'Raiffeisen', label: this.$t('Raiffeisen') },
        { value: 'ProCredit', label: this.$t('ProCredit') },
        { value: 'NLB Banka', label: this.$t('NLB Banka') },
        { value: 'Banka Ekonomike', label: this.$t('Banka Ekonomike') },
        { value: 'TEB', label: this.$t('TEB') },
      ],
      expense: '',
      expenses: ['Rryma', 'Qeraja', 'Uji', 'Derivate', 'Pajisje', 'Udhetimi', 'Bonuse', 'Tjera',
      ],
      vacation2: [
        { value: 'Raiffeisen', label: this.$t('Raiffeisen') },
        { value: 'ProCredit', label: this.$t('ProCredit') },
        { value: 'NLB Banka', label: this.$t('NLB Banka') },
        { value: 'Banka Ekonomike', label: this.$t('Banka Ekonomike') },
        { value: 'TEB', label: this.$t('TEB') },
      ],
      hasVacation: false,
      isPhoneNumberValid: null,
      form: {
        employeeUserId: this.selectedId,
        startDate: null,
        endDate: null,
        vacationType: '',
      },
      form2: {
        managerId: this.selectedId,
        coManagerId: '',
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
    };
  },
  validations: {
    time1: {
      required,
    },
    time2: {
      required,
    },
    reason: {
      required,
    },
  },

  computed: {
    ...mapGetters([
      'getfreeEmployees',
      'getLoggedInUser',
      'getFiliales',
      'getFreeManagers',
      'getHasVacation',
    ]),
    // isValidDate() {
    //   return this.time1 && this.time2 && this.time1 <= this.time2;
    // },
    // isDateMoreThan6MonthsAway() {
    //   const inputMoment = moment(this.date, 'YYYY-MM-DD');
    //   const currentDate = moment();
    //   const futureDate = moment(currentDate).add(6, 'months');
    //   return inputMoment.isAfter(futureDate);
    // },
    vacationTime() {
      if (this.client == 'DrenushaXharra') {
        if (this.sixmonth == true) {
          return this.vacation1
        }
        return this.vacation2
      }
      return this.vacation1
    },
    filteredManagers() {
      return this.getFreeManagers.filter((manager) => {
        return manager.userId !== this.userObj.userId;
      });
    },
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = new Date(this.time1.split('-').reverse().join('-')).getTime();
      const end = new Date(this.time2.split('-').reverse().join('-')).getTime();
      return start <= end;
    },
  },
  watch: {
    reason(value) {
      if (value == 'Other') {
        this.showInput = true;
        this.form.vacationType = '';
      } else {
        this.showInput = false;
        this.form.vacationType = value;
      }
    },
    date(value) {
      const specificDate = moment(value);
      const today = moment();
      const differenceInDays = today.diff(specificDate, 'days');
      this.sixmonth = differenceInDays > 180;
      console.log('difference ', this.sixmonth);
    },

    time1(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.startDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.endDate = moment
        .tz(value2, 'DD-MM-yyyyTHH:mm:ss', res)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    },
    // eslint-disable-next-line func-names
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
      }
    },
    // eslint-disable-next-line func-names
    'form.startDate': function (value) {
      if (!this.resettingForm && this.form.endDate != null) {
        this.getAlreadyRequestedVacation({
          employeeUserId: this.form.employeeUserId,
          startDate: value,
          endDate: this.form.endDate,
        });
      }
      this.resettingForm = false;
    },
    // eslint-disable-next-line func-names
    'form.endDate': function (value) {
      this.getAlreadyRequestedVacation({
        employeeUserId: this.form.employeeUserId,
        startDate: this.form.startDate,
        endDate: value,
      });
    },
    // eslint-disable-next-line func-names
    'form.vacationType': function (newValue) {
      this.form.vacationType = newValue.replace(/\s+/g, '');
    },
    selectedId: {
      handler(value) {
        this.form.employeeUserId = value;
        this.form2.managerId = value;
      },
      immediate: true,
    },
    manager: {
      handler(value) {
        this.form2.coManagerId = value.userId;
      },
      immediate: true,
    },
  },
  created() {
    // console.log('sadafs', this.user);
  },
  methods: {
    ...mapActions([
      'getAlreadyRequestedVacation',
      'releaseAndReplaceEmployeeFromTour',
      'addCoManagerToStores',
      'freeEmployee',
      'releaseEmployeeFromTour',
    ]),
    handleModalShown() {
      // Call your method here
      this.form.employeeUserId = this.selectedId;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmitRequestVacation() {
      this.formSubmitted = true;
      this.$v.reason.$touch();
      if (this.$v.reason.$anyError) {
        return;
      }
      this.$v.time1.$touch();
      if (this.$v.time1.$anyError) {
        return;
      }
      this.$v.time2.$touch();
      if (this.$v.time2.$anyError) {
        return;
      }

      this.$emit('pushFormUser', this.form);
      if (this.userObj.personRole == 'Manager') {
        await this.addCoManagerToStores(this.form2);
      }
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.resettingForm = true;
      this.selectedItemId = null;
      this.reason = '';
      this.form.employeeUserId = '';
      this.form.startDate = null;
      this.form.endDate = null;
      this.form.vacationType = '';
      this.form.notes = '';
      this.time1 = '';
      this.time2 = '';
      this.notes = ''
      this.formSubmitted = false;
      this.form2.coManagerId = null;
      this.manager = null;
      this.$store.commit('SET_ALREADY_REQUESTED_VACATION', false);
      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

  <style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .vacation {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .form2 {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .uploadButtons {
    display: flex;
    align-items: flex-end;
  }
  </style>
